<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.06969 2.0696C2.43219 1.70726 3.06656 1.59079 3.55852 2.08254L7.00226 5.52477L10.446 2.08249C10.938 1.59074 11.5594 1.69427 11.9349 2.06955C12.2974 2.43189 12.4139 3.06598 11.9219 3.55773L8.47815 7.00002L11.9219 10.4423C12.4139 10.934 12.2973 11.5681 11.9348 11.9305C11.5594 12.3057 10.938 12.4093 10.446 11.9175L7.00226 8.47527L3.55856 11.9175C3.0666 12.4092 2.43222 12.2927 2.06973 11.9304C1.69428 11.5551 1.59071 10.934 2.08267 10.4422L5.52637 7.00002L2.08263 3.55779C1.59067 3.06604 1.69424 2.44488 2.06969 2.0696Z"
    />
  </svg>
</template>
